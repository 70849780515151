import {defineStore} from "pinia";

// export const enum
export const navbarOptions = {
    HOME: 'home',
    ATHLETES: 'athletes',
    GROUPS: 'groups',
    ASSESSMENTS: 'assessments',
    ADMIN: 'admin',
    NONE: 'none',
}

export const useNavBarOptionsStore = defineStore({
    id: 'navBarOptions',
    state: () => ({
        dropdownOpen: false,
        hamburgerOpen: false,
        selectedNavbarItem: navbarOptions.NONE,
    }),
    actions: {
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen
        },
        toggleHamburger() {
            this.hamburgerOpen = !this.hamburgerOpen
        },
        closeHamburger() {
            this.hamburgerOpen = false
        },
        closeDropdown() {
            this.dropdownOpen = false
        },
        setSelected(selected) {
            this.selectedNavbarItem = selected
        },
    }
})