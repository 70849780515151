<template>
  <!--dropdown background-->
  <div
    v-if="dropdownOpen || hamburgerOpen"
    class="absolute w-full h-full"
    @click.prevent="
      dropdownOpen = false;
      hamburgerOpen = false;
    "
  ></div>

  <!--header-->
  <nav
    class="border-b-2 flex text-xl font-medium sticky top-0 bg-white h-[100px] z-20"
  >
    <div :class="isAuthenticated ? 'max-lg:hidden' : ''">
      <router-link to="/">
        <img
          alt="logo"
          class="m-1 mx-5 mr-auto w-48 h-38 mt-6"
          src="../assets/kinetikIQDarkCropped.png"
        />
      </router-link>
    </div>

    <!--navigation-->
    <div class="relative z-20 w-[100px]" v-if="isAuthenticated">
      <button
        id="navbar-hamburger"
        class="visible min-lg:invisible ml-[10px] mt-[10px]"
        @click="hamburgerOpen = !hamburgerOpen"
      >
        <svg
          class="lg:max-h-0 lg:max-w-0"
          width="75%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#2c3e50"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 18L20 18"
              stroke="#2c3e50"
              stroke-width="2"
              stroke-linecap="round"
            ></path>
            <path
              d="M4 12L20 12"
              stroke="#2c3e50"
              stroke-width="2"
              stroke-linecap="round"
            ></path>
            <path
              d="M4 6L20 6"
              stroke="#2c3e50"
              stroke-width="2"
              stroke-linecap="round"
            ></path>
          </g>
        </svg>
      </button>
      <div
        class="ml-1 absolute lg:divide-none divide-y divide-gray-100 lg:flex lg:visible rounded-lg lg:rounded-none shadow-lg lg:shadow-none lg:top-0 lg:w-fit max-lg:bg-white mt-3"
        :class="
          hamburgerOpen
            ? 'max-lg:visible w-fit h-fit ml-0'
            : 'max-lg:invisible w-0 h-0'
        "
        id="navbar-navigation"
      >
        <router-link
          to="/dashboard"
          class="hover:font-bold"
          :class="{
            'hamburger-link': true,
            selected: selectedNavbarItem === navbarOptions.HOME,
          }"
        >
          <div class="p-6">Home</div>
        </router-link>
        <router-link
          :to="
            '/' +
            athleteOrPatient.lowercasePlural
          "
          class="hover:font-bold"
          :class="{
            'hamburger-link': true,
            selected: selectedNavbarItem === navbarOptions.ATHLETES,
          }"
        >
          <div class="px-4 py-6">
            {{ athleteOrPatient.capitalisedPlural }}
          </div>
        </router-link>
        <router-link
          to="/groups"
          class="hover:font-bold"
          :class="{
            'hamburger-link': true,
            selected: selectedNavbarItem === navbarOptions.GROUPS,
          }"
        >
          <div class="px-4 py-6">Groups</div>
        </router-link>
        <router-link
          to="/assessments"
          class="hover:font-bold"
          :class="{
            'hamburger-link': true,
            selected: selectedNavbarItem === navbarOptions.ASSESSMENTS,
          }"
        >
          <div class="px-4 py-6">Assessments</div>
        </router-link>

        <!--only allow admins-->
        <template v-if="isUserAdmin(currentCustomer.tier)">
          <router-link
            to="/adminDashboard"
            class="hover:font-bold"
            :class="{
              'hamburger-link': true,
              selected: selectedNavbarItem === navbarOptions.ADMIN,
            }"
          >
            <div class="px-4 py-6">Admin</div>
          </router-link>
        </template>
      </div>
    </div>

    <!--dropdown button-->
    <div class="ml-auto m-4 w-fit my-auto relative z-10" v-if="isAuthenticated">
      <button
        id="pf-picture"
        class="flex"
        @click="dropdownOpen = !dropdownOpen"
      >
        <img
          v-if="brokenUrl !== imageUrl"
          alt="pf-picture"
          class="w-[75px] h-[75px] rounded-full m-[10px] object-center object-cover"
          :src="imageUrl"
          @error="brokenUrl = imageUrl"
        />
        <DefaultProfilePicture
          v-else
          :name="name"
          class="h-[75px] w-[75px] m-[10px] text-2xl"
        />

        <span class="my-auto p-1">{{ name }}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 my-auto"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>

      <!--dropdown content-->
      <div
        class="bg-white divide-y divide-gray-100 rounded-lg shadow w-full absolute text-left"
        :hidden="!dropdownOpen"
      >
        <!--dropdown header-->
        <div class="px-4 py-3">
          <p class="leading-5">Signed in with</p>
          <p class="font-semibold text-sm leading-5 text-gray-900 truncate">
            {{ email }}
          </p>
        </div>

        <!--subscription info-->
        <div class="px-4 py-3">
          <p class="flex-1">Subscription:</p>
          <div
            class="my-0 text-xs border border-gray-800 rounded-lg bg-slate-100 p-1 text-center w-[50%] mx-auto"
            id="curr-subscription"
          >
            {{ currentCustomerTier }}
          </div>
        </div>

        <!--dropdown body-->
        <div class="p-2">
          <!--profile settings button-->
          <router-link
            @click="dropdownOpen = false"
            class="my-2"
            to="/settings"
          >
            <button
              class="my-2 transition ease-in-out duration-150 items-center flex"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 m-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              Settings
            </button>
          </router-link>

          <!-- Feedback button -->
          <router-link
            @click="dropdownOpen = false"
            class="my-2"
            to="/feedback"
          >
            <button
              class="my-2 transition ease-in-out duration-150 items-center flex"
            >
              <svg
                stroke-width="1"
                stroke="currentColor"
                class="w-6 h-6 m-1"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <!-- Generator: Sketch 41.2 (35397) - http://www.bohemiancoding.com/sketch -->
                <title>ic_feedback</title>
                <desc>Created with Sketch.</desc>
                <defs></defs>
                <g
                  id="Icons"
                  stroke="none"
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <g
                    id="24-px-Icons"
                    transform="translate(-122.000000, -218.000000)"
                    stroke="currentColor"
                  >
                    <g
                      id="ic_feedback"
                      transform="translate(120.000000, 216.000000)"
                    >
                      <g id="feedback">
                        <g
                          transform="translate(3.000000, 3.000000)"
                          stroke-width="1.5"
                        >
                          <path
                            d="M12.75,13 L16.0049107,13 C17.1073772,13 18,12.1033337 18,10.9972399 L18,2.00276013 C18,0.893542647 17.1067681,0 16.0049107,0 L1.99508929,0 C0.892622799,0 0,0.896666251 0,2.00276013 L0,10.9972399 C0,12.1064574 0.893231902,13 1.99508929,13 L5.25,13 L9,18 L12.75,13 Z"
                            id="Combined-Shape"
                          ></path>
                          <path d="M5,4 L13,4" id="Line"></path>
                          <path d="M5,9 L13,9" id="Line"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>

              Feedback
            </button>
          </router-link>

          <!--sign out button-->
          <button
            @click="logoutPopup"
            class="text-red-600 hover:text-red-500 transition ease-in-out duration-150 items-center flex my-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 m-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
              />
            </svg>

            Sign out
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { useMsal } from "@/msal/useMsal";
import { useIsAuthenticated } from "@/msal/useIsAuthenticated";
import { msalInstance } from "@/msal/authConfig";
import { useProfileDataStore } from "@/stores/ProfileDataStore";
import DefaultProfilePicture from "@/components/DefaultProfilePicture.vue";
import { isUserAdmin } from "@/utils/CustomerTiersUtils";
import {
  navbarOptions,
  useNavBarOptionsStore,
} from "@/stores/NavBarOptionsStore";
import { currentCustomer } from "@/stores/CustomerMetadataStore";
import { athleteOrPatient, updateTokenClaims } from "@/utils/UserCategoriesUtils";

export default {
  name: "header-component",

  components: { DefaultProfilePicture },

  setup() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const profileDataStore = useProfileDataStore();
    const navBarOptionsStore = useNavBarOptionsStore();
    const logoutPopup = () => {
      instance.logoutRedirect({
        mainWindowRedirectUri: "/",
      });
    };

    return {
      isAuthenticated,
      logoutPopup,
      profileDataStore,
      navBarOptionsStore,
    };
  },

  data() {
    return {
      profilePicture: "",
      name: "",
      email: "",
      brokenUrl: "",
      athleteOrPatient,
      currentCustomer,
      selectedNavbarItem: this.navBarOptionsStore.selectedNavbarItem,
    };
  },

  methods: {
    isUserAdmin,
  },

  computed: {
    navbarOptions() {
      return navbarOptions;
    },

    currentCustomerTier() {
      return this.currentCustomer.getTier();
    },

    /**
     * Returns the image url for the profile picture
     * @returns {string} - the image url
     */
    imageUrl() {
      return this.profilePicture + this.profileDataStore.profileString;
    },
    dropdownOpen: {
      get() {
        return this.navBarOptionsStore.dropdownOpen;
      },
      set(value) {
        this.navBarOptionsStore.dropdownOpen = value;
      },
    },
    hamburgerOpen: {
      get() {
        return this.navBarOptionsStore.hamburgerOpen;
      },
      set(value) {
        this.navBarOptionsStore.hamburgerOpen = value;
      },
    },
  },

  watch: {
    hamburgerOpen() {
      this.hamburgerOpen = this.navBarOptionsStore.hamburgerOpen;
      if (this.hamburgerOpen) this.navBarOptionsStore.closeDropdown();
    },

    dropdownOpen() {
      this.dropdownOpen = this.navBarOptionsStore.dropdownOpen;
      if (this.dropdownOpen) this.navBarOptionsStore.closeHamburger();
    },

    currentCustomer() {
      this.currentCustomer = currentCustomer;
    },

    "navBarOptionsStore.selectedNavbarItem"() {
      this.selectedNavbarItem = this.navBarOptionsStore.selectedNavbarItem;
    },
  },

  async mounted() {
    await updateTokenClaims(); // hacky way to wait until the token claims are available
    const newAccount = msalInstance.getActiveAccount();
    if (!newAccount) return;
    if (newAccount.idTokenClaims === undefined) {
      return;
    }
    this.name = newAccount
      ? `${newAccount.idTokenClaims.given_name} ${newAccount.idTokenClaims.family_name}`
      : "";
    this.email = newAccount ? newAccount.idTokenClaims.emails[0] : "";
    this.profilePicture = newAccount
      ? process.env.VUE_APP_BLOB_STORAGE_ACCOUNT_URI +
        "/coach-profile-pictures/" +
        newAccount.localAccountId +
        ".png?cache=" +
        Date.now()
      : "";
  },
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .x-sm-text-on-phone {
    font-size: x-small;
    font-weight: bold;
  }
}

.selected {
  font-weight: bolder;
}
</style>
