import {defineStore} from "pinia";
import {msalInstance, tokenRequest} from "@/msal/authConfig";
import axios from "axios";
import {getTokenRedirect} from "@/msal/authRedirect";
import {apiConfig} from "@/msal/apiConfig";
import {useProfileDataStore} from "@/stores/ProfileDataStore";
import {currentCustomer} from "@/stores/CustomerMetadataStore";
import {CustomerMetadata} from "@/models/CustomerMetadata";
import signalRService from "@/utils/SignalRService";

// Add a response interceptor to handle 401 errors and logout the user if they occur
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    msalInstance.logoutRedirect().then(() => {
      console.log("Logged out")
    }).catch((e) => {
      console.log("Error logging out", e)
    });
  }
  return Promise.reject(error);
});

export const useDataStore = defineStore("dataStore", {

  state: () => {
    return {
      athletes: [],
      athleteCustomExerciseData: {},
      assessments: [],
      notes: [],
      assessmentData: {},
      notifications: [],
      exercises: [],
      groups: [],
      timeOfLastNoteFetch: 0,
      timeOfLastAthleteFetch: 0,
      timeOfLastAssessmentFetch: 0,
      timeOfLastCustomerFetch: 0,
      profilePicture: null,
      customerFeedback: [],
      events: {},
      allCustomers: [],
      currentCustomer: currentCustomer,
    };
  },
  actions: {
    // Function to emit events from dataStore
    //How to use : this.emit('Event Name', OptionalData) ...args are optional and can be used send data
    emit(eventName, ...args) {
      if (!this.events[eventName]) return;

      this.events[eventName].forEach((listener) => {
        listener(...args);
      });
    },

    // Function to register event listeners
    //How to use : From any Component where you have a reference from dataStore you can listen to events by using the "on" method
    //example : this.dataStore.on('Event name ', (data) => {});
    on(eventName, callback) {
      if (!this.events[eventName]) {
        this.events[eventName] = [];
      }
      this.events[eventName].push(callback);
    },
    async getAthleteCustomExerciseData(athleteId, exerciseId) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      await axios
        .get(apiConfig.apiUrl + "/custom-exercise/api/athleteExercise/" + athleteId + "/" + exerciseId, {
          headers: {Authorization: bearer, 'Content-Type': 'application/json'},
        })
        .then(async (response) => {
          if (response.status == 200) {
            this.athleteCustomExerciseData = response.data;
          }
        })
        .catch(() => {
          this.createNotification("Error Fetching Athlete custom exercise data", "Error");
        });
    },

    // request pipeline rerun
    async requestPipelineRerun(assessmentId, repCount) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      await axios
        .post(apiConfig.apiUrl + "/movement-assessment/api/pipelinererunrequest/" + assessmentId + "/rerun" + (repCount != null ? "?repCount=" + repCount : ""), {}, {
          headers: {Authorization: bearer, 'Content-Type': 'application/json'},
        })
        .then(async (response) => {
          if (response.status === 200) {
            this.createNotification("Rerunning the analysis", "Info");
            this.emit("rerun requested")
          } else {
            this.createNotification("Error requesting the reanalysis", "Error");
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.createNotification("Reanalysis is already in progress. Please await for completion.", "Warning");
          } else {
            console.log(e);
            this.createNotification("Error requesting the reanalysis", "Error");
          }
        });
    },

    //Assessment data to populate visualizations on Assessment's page
    async fetchAssessmentDataFile(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      return new Promise((resolve, reject) => {
        axios
          .get(apiConfig.apiUrl + "/data-visualisation/api/vizdata/getByAssessmentId?assessmentId=" + id, {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          })
          .then(async (response) => {
            this.assessmentData = response.data;
            resolve(response.data);
          })
          .catch((e) => {
            if (e.response.status === 404) {
              this.assessmentData = {"Error": e.response.status};
              resolve({"Error": e.response.status})
            } else {
              console.log(e)
              reject(e.response.status);
            }
          });
      });
    },

    async negotiateSignalRConnection() {
      try {
        const connection = await signalRService.initialiseConnection();

        connection.on("ReceiveNotification", (type, message) => {
          this.createNotification(message, type);
        });
      } catch (error) {
        console.error("Failed to establish SignalR connection:", error);
      }
    },

    async fetchAllAthletes() {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      if (
        this.timeOfLastAthleteFetch + 1000 * process.env.VUE_APP_REFRESH_TIME <
        Date.now()
      ) {
        return new Promise((resolve, reject) => {
          axios
            .get(apiConfig.apiUrl + "/movement-assessment/api/athlete", {
              headers: {Authorization: bearer, 'Content-Type': 'application/json'},
            })
            .then((response) => {
              response.data.map((athlete) => {
                if (
                  this.athletes.find(
                    (ath) => ath.athleteId === athlete.athleteId
                  ) === undefined
                )
                  this.athletes.push(athlete);
                else {
                  const athleteIndex = this.athletes
                    .findIndex((ath) => ath.athleteId === athlete.athleteId);
                  this.athletes[athleteIndex] = athlete;
                }
              });
              resolve(response.data);
            })
            .catch((reason) => {
              console.log(reason);
              reject(reason);
            });
          this.timeOfLastAthleteFetch = Date.now();
        });
      }
    },

    async fetchAthleteData(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve) => {
        axios
          .get(apiConfig.apiUrl + "/movement-assessment/api/athlete/" + id, {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          })
          .then(async (response) => {
            let currAthlete = this.athletes.find(
              (athlete) => athlete.athleteId === id
            );
            currAthlete = response.data;
            resolve(currAthlete);
          })
          .catch((reason) => {
            console.log(reason);
            this.createNotification("Error fetching athlete data", "Error");
          });
      });
    },

    async fetchAssessments(
      page = 0,
      pageSize = 20,
      sortOrder = "desc",
      groupFilter = null,
      exerciseFilter = null,
      startDate = null,
      endDate = null
    ) {

      if (this.exercises.length === 0) {
        await this.fetchExercises().catch(() => {
          this.createNotification(
            "Error fetching exercises while fetching assessments",
            "Error"
          )
        });
      }
      const token = await getTokenRedirect(tokenRequest)
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .get(
            apiConfig.apiUrl +
            "/movement-assessment/api/assessment?" +
            "page=" + page +
            "&pageSize=" + pageSize +
            "&sortOrder=" + sortOrder +
            (groupFilter !== null ? "&groupFilter=" + groupFilter : "") +
            (exerciseFilter !== null ? "&exerciseFilter=" + exerciseFilter : "") +
            (startDate !== null ? "&startDate=" + startDate : "") +
            (endDate !== null ? "&endDate=" + endDate : ""),
            {
              headers: {Authorization: bearer, 'Content-Type': 'application/json'},
            }
          )
          .then((response) => {
              resolve(this.assessmentArrayToAssessmentGlobal(response));
            }
          )
          .catch((reason) => {
            console.log(reason);
            this.createNotification("Error fetching assessments", "Error");
            reject(reason);
          });
      });
    },

    async fetchRecentAthleteAssessments() {
      if (this.exercises.length === 0) {
        await this.fetchExercises().catch(() => {
          this.createNotification(
            "Error fetching exercises while fetching assessments",
            "Error"
          )
        });
      }
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      await axios
        .get(apiConfig.apiUrl + "/movement-assessment/api/assessment/athlete", {
          headers: {Authorization: bearer, 'Content-Type': 'application/json'},
        })
        .then(this.assessmentArrayToAssessmentGlobal)
        .catch((reason) => {
          console.log(reason);
          this.createNotification("Error fetching recent assessments", "Error");
        });
      this.emit('fetchedAssessments');
    },

    async fetchAllAssessmentsForAthlete(athleteId, exerciseFilter = null) {
      if (this.exercises.length === 0) {
        await this.fetchExercises().catch(() => {
          this.createNotification(
            "Error fetching exercises while fetching assessments",
            "Error"
          )
        });
      }
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      return new Promise((resolve) => {
        axios
        .get(apiConfig.apiUrl + "/movement-assessment/api/assessment/athlete/" + athleteId 
        + (exerciseFilter !== null ? "?&exerciseFilter=" + exerciseFilter : ""), {
          headers: {Authorization: bearer, 'Content-Type': 'application/json'},
        })
        .then((response) => {
          resolve(this.assessmentArrayToAssessmentGlobal(response));
        })
        .catch((reason) => {
          console.log(reason);
          this.createNotification(
            "Error fetching assessments for an athlete " + athleteId,
            "Error"
          );
        });
      });
    },

    async fetchAssessmentData(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      await axios
        .get(apiConfig.apiUrl + "/movement-assessment/api/assessment/" + id, {
          headers: {Authorization: bearer, 'Content-Type': 'application/json'},
        })
        .then(async (response) => {
          if (
            this.assessments.find(
              (assessment) => assessment.assessmentId === id
            ) === undefined
          ) {
            if (response.data.exerciseId !== undefined) {
              let exercise = this.exercises.find(
                (exercise) => exercise.exerciseId === response.data.exerciseId
              );
              if (exercise !== undefined)
                response.data.exerciseName = exercise.name;
              else {
                this.fetchExerciseData(response.data.exerciseId).then((exercise) => {
                  response.data.exerciseName = exercise.name;
                }).catch(() => {
                  this.createNotification(
                    "Error fetching exercise data for assessment",
                    "Error"
                  );
                });
              }
            }
            this.assessments.push(response.data);
          } else {
            const assessmentIndex = this.assessments.findIndex(
              (assessment) => assessment.assessmentId === id
            );
            this.assessments[assessmentIndex].assessmentId =
              response.data.assessmentId;
            this.assessments[assessmentIndex].athleteId =
              response.data.athleteId;
            this.assessments[assessmentIndex].exerciseId =
              response.data.exerciseId;
            this.assessments[assessmentIndex].timestamp =
              response.data.timestamp;
            this.assessments[assessmentIndex].csvData = response.data.csvData;
            this.assessments[assessmentIndex].videoUrl = response.data.videoUrl;

            const exercise = this.exercises.find(
              (exercise) => exercise.exerciseId === response.data.exerciseId
            );
            if (exercise !== undefined)
              this.assessments[assessmentIndex].exerciseName = exercise.name;
          }
        })
        .catch((reason) => {
          console.log(reason);
          this.createNotification("Error fetching assessment data", "Error");
        });
    },

    /**
     * Send request to API to update an assessment
     * @param assessment body of updated assessment (updated exercise and athlete ids)
     * @returns {Promise<*>} returns 200 if successful or Error status otherwise
     */
    async updateAssessmentData(assessment) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      let res;
      await axios
        .put(
          apiConfig.apiUrl + "/movement-assessment/api/assessment/" + assessment.assessmentId,
          {
            assessmentId: assessment.assessmentId,
            timestamp: assessment.timestamp,
            exerciseId: assessment.exerciseId,
            athleteId: assessment.athleteId,
            repetitions: assessment.repetitions
          },
          {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          }
        )
        .then(() => {
          this.createNotification(
            "Assessment updated",
            "success"
          );
          res = 200;
        })
        .catch((reason) => {
          this.createNotification(
            "Error updating assessment",
            "Error"
          );
          res = reason.status
        });

      return res;
    },

    async updateAssessmentResults(assessmentId, results){
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      let res;
      await axios
        .put(
          apiConfig.apiUrl + "/movement-assessment/api/assessment/" + assessmentId + "/results",
          results,
          {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          }
        )
        .then(() => {
          this.createNotification(
            "Assessment updated",
            "success"
          );
          res = 200;
        })
        .catch((reason) => {
          this.createNotification(
            "Error updating assessment",
            "Error"
          );
          res = reason.status
        });

      return res;
    },

    async hideAssessment(assessmentId) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      let res;

      await axios
        .put(
          apiConfig.apiUrl + "/movement-assessment/api/assessment/hideAssessment/" + assessmentId, {}, {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          })
        .then(() => {
          this.createNotification(
            "Assessment deleted",
            "success"
          );
          res = 200;
        })
        .catch((reason) => {
          this.createNotification(
            "Error deleting assessment",
            "Error"
          );
          console.log(reason);
          res = reason.status
        });

      return res;
    },

    /**
     * Assessment Notes Methods
     */
    async createAssessmentNote(note) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .post(apiConfig.apiUrl + "/movement-assessment/api/notes", note, {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          })
          .then((response) => {
            this.notes.push(response.data);
            resolve(response.data);
          })
          .catch((e) => {
            this.createNotification(
              "Error creating note for assessment",
              "Error"
            );
            reject(e);
          });
      });
    },

    async fetchNotes(
      page = 0,
      pageSize = 20,
      sortOrder = "desc",
      filter = null,
      startDate = null,
      endDate = null
    ) {

      const token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .get(
            apiConfig.apiUrl +
            "/movement-assessment/api/notes?page=" +
            page +
            "&pageSize=" +
            pageSize +
            "&sortOrder=" +
            sortOrder +
            (filter !== null ? "&groupFilter=" + filter : "") +
            (startDate !== null ? "&startDate=" + startDate : "") +
            (endDate !== null ? "&endDate=" + endDate : ""),
            {
              headers: {Authorization: bearer, 'Content-Type': 'application/json'},
            }
          )
          .then((response) =>
            resolve(this.notesArrayToAssessmentGlobal(response))
          )
          .catch((reason) => {
            console.log(reason);
            this.createNotification("Error fetching assessments", "Error");
            reject(reason);
          });
      });
    },

    async fetchNoteByAssessmentId(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve) => {
        axios
          .get(apiConfig.apiUrl + "/movement-assessment/api/notes/getByAssessment/" + id, {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          })
          .then(async (response) => {
            let currNote = this.notes.find(
              (note) => note.assessmentId === id
            );
            currNote = response.data;
            resolve(currNote);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404) {
              resolve(null); // Return null for 404 errors
            } else {
              this.createNotification("Error fetching note data", "Error");
            }
          });
      });
    },

    async fetchAllNotesByAthleteId(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .get(apiConfig.apiUrl + "/movement-assessment/api/notes/getByAthlete/" + id, {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          })
          .then((response) =>
            resolve(this.notesArrayToAssessmentGlobal(response))
          )
          .catch((error) => {
            if (error.response && error.response.status === 404) {
              resolve(null); // Return null for 404 errors
            } else {
              this.createNotification("Error fetching note data", "Error");
              reject(error);
            }
          });
      });
    },

    /**
     * Send request to API to update a note
     * @param note body of updated note (updated note content andpain level)
     * @returns {Promise<*>} returns 200 if successful or Error status otherwise
     */
    async updateAssessmentNoteData(note) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .put(
            apiConfig.apiUrl + "/movement-assessment/api/notes/" + note.noteId,
            {
              noteContent: note.noteContent,
              painLevel: note.painLevel,
            },
            {
              headers: {Authorization: bearer, 'Content-Type': 'application/json'},
            }
          )
          .then(() => {
            resolve(200);
          })
          .catch((error) => {
            this.createNotification("Error updating note", "Error");
            reject(error.status);
          });
      });
    },

    async deleteAssessmentNote(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      return new Promise((resolve, reject) => {
        axios
          .delete(apiConfig.apiUrl + "/movement-assessment/api/notes/" + id, {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          })
          .then(() => {
            this.createNotification("Note deleted", "Info");
            resolve(200);
          })
          .catch((error) => {
            this.createNotification(
              "Error deleting note with reason: " + error,
              "Error"
            );
            reject(error.status);
          });
      });
    },

    async fetchExercises() {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .get(apiConfig.apiUrl + "/custom-exercise/api/exercise", {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          })
          .then((response) => {
            this.exercises = response.data;
            resolve(response.data);
          })
          .catch((reason) => {
            console.log(reason);
            reject(reason);
          });
      });
    },

    async fetchExerciseData(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .get(apiConfig.apiUrl + "/custom-exercise/api/exercise/" + id, {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((reason) => {
            console.log(reason);
            reject(reason);
          });
      });
    },

    async registerAthlete(athlete) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .post(apiConfig.apiUrl + "/movement-assessment/api/athlete", athlete, {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          })
          .then((response) => {
            // if response data doesn't contain groups, add an empty array
            if (!response.data.groups) {
              response.data.groups = [];
            }

            this.athletes.push(response.data);
            this.createNotification(
              "Athlete " + athlete.firstName + " " + (athlete.lastName || '') + " created",
              "success"
            );

            resolve(response.data);
          })
          .catch((e) => {
            this.createNotification(
              "Error creating athlete " + athlete.firstName + " " + (athlete.lastName || ''),
              "Error"
            );
            reject(e);
          });
      });
    },

    async updateAthleteGroups(athleteId, groups) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) =>
        axios
          .put(
            apiConfig.apiUrl + "/movement-assessment/api/athlete/" + athleteId + "/group", groups,
            {
              headers: {Authorization: bearer, 'Content-Type': 'application/json'},
            }
          )
          .then((response) => {
            this.athletes.find(
              (athlete) => athlete.athleteId === Number(athleteId)
            ).groups = response.data;
            resolve(response.data);
          })
          .catch((e) => {
            console.log(e)
            reject(e);
          })
      );
    },

    async deleteGroupFromAthlete(athleteId, groupId) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      await axios
        .delete(
          apiConfig.apiUrl + "/movement-assessment/api/athlete/" + athleteId + "/group/" + groupId,
          {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          }
        )
        .then(() => {
          this.athletes.find(
            (athlete) => athlete.athleteId === Number(athleteId)
          ).groups = this.athletes
            .find((athlete) => athlete.athleteId === Number(athleteId))
            .groups.filter((group) => group.groupId !== groupId);
        })
        .catch((reason) => {
          this.createNotification(
            "Error deleting group from athlete with reason: " + reason,
            "Error"
          );
        });
    },


    async updateAthlete(inputAthlete) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      return new Promise((resolve, reject) => {
        axios
          .put(
            apiConfig.apiUrl + "/movement-assessment/api/athlete/" + inputAthlete.athleteId,
            {
              athleteId: inputAthlete.athleteId,
              firstName: inputAthlete.firstName,
              lastName: inputAthlete.lastName,
              dob: inputAthlete.dob,
              bodyweight: inputAthlete.bodyweight,
              height: inputAthlete.height,
              gender: inputAthlete.gender,
              hideFace: inputAthlete.hideFace,
            },
            {
              headers: {Authorization: bearer, 'Content-Type': 'application/json'},
            }
          )
          .then((response) => {
            let updatedAthlete = this.athletes.find((athlete) => (athlete.athleteId === response.data.athleteId));
            updatedAthlete.firstName = response.data.firstName;
            updatedAthlete.lastName = response.data.lastName;
            updatedAthlete.dob = response.data.dob;
            updatedAthlete.bodyweight = response.data.bodyweight;
            updatedAthlete.gender = response.data.gender;
            updatedAthlete.height = response.data.height;
            resolve(response.data);
          })
          .catch((e) => {
              console.log(e)
              reject(e);
            }
          );
      })
    },

    async deleteAthlete(athlete) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      const athleteData = this.athletes.find((a) => a.athleteId === athlete);

      await axios
        .delete(apiConfig.apiUrl + "/movement-assessment/api/athlete/" + athlete, {
          headers: {Authorization: bearer, 'Content-Type': 'application/json'},
        })
        .then(() => {
          this.athletes = this.athletes.filter((a) => a.athleteId !== athlete);
          this.createNotification(
            "Athlete " + athleteData.firstName + " " + (athleteData.lastName || ' ') + " deleted",
            "success"
          );
        })
        .catch(() => {
          this.createNotification(
            "Error deleting athlete " + athleteData.firstName + " " + (athleteData.lastName || ' '),
            "Error"
          );
        });
    },

    async registerGroup(group) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      await axios
        .post(apiConfig.apiUrl + "/movement-assessment/api/group", group, {
          headers: {Authorization: bearer, 'Content-Type': 'application/json'},
        })
        .then((response) => {
          this.groups.push(response.data);
          this.createNotification(
            "Group " + response.data.name + " created",
            "success"
          );
        })
        .catch(() => {
          this.createNotification(
            "Error creating group " + group.name,
            "Error"
          );
        });
    },

    async registerGroupForAthlete(athleteId, group) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      await axios
        .post(
          apiConfig.apiUrl + "/movement-assessment/api/athlete/" + athleteId + "/group/" + group.groupId,
          {
            athleteId: athleteId,
            groupId: group.groupId,
          },
          {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          }
        )
        .then(() => {
          try {
            const athlete = this.athletes.find(
              (athlete) => athlete.athleteId === Number(athleteId)
            );

            if (!athlete.groups) {
              athlete.groups = []; // Initialize groups as an empty array if it doesn't exist
            }

            athlete.groups.push(group);
            this.emit('athleteAddedToGroup');
          } catch (e) {
            console.log(e)
          }
        })
        .catch((e) => {
          console.log(e)
          this.createNotification(
            "Error adding to group",
            "Error"
          );
        });
    },

    async fetchAllGroups() {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      await axios
        .get(apiConfig.apiUrl + "/movement-assessment/api/group", {
          headers: {Authorization: bearer, 'Content-Type': 'application/json'},
        })
        .then((response) => {
          this.groups = response.data;
          this.sortGroupsByName();
          this.emit('fetchedGroups');
        })
        .catch((e) => {
          console.log(e)
          this.createNotification("Error fetching groups", "Error");
        });
    },

    async updateGroup(group) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .put(apiConfig.apiUrl + "/movement-assessment/api/group/" + group.groupId, group, {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          })
          .then((response) => {
            resolve(Number(response.status));
          })
          .catch((e) => {
            console.log(e)
            reject(e);
          });
      })
    },

    async deleteGroup(group) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .delete(apiConfig.apiUrl + "/movement-assessment/api/group/" + group.groupId, {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          })
          .then((response) => {
            resolve(Number(response.status))
          })
          .catch((reason) => {
            console.log(reason)
            reject(reason);
          });
      });
    },

    async uploadProfilePicture(image) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      const formData = new FormData();
      formData.append("file", image);

      await axios
        .post(apiConfig.apiUrl + "/movement-assessment/api/profile", formData, {
          headers: {Authorization: bearer, 'Content-Type': 'multipart/form-data'},
        })
        .then((response) => {
          if (response.status === 201) {
            this.createNotification("Profile picture uploaded", "Info");
            useProfileDataStore().updateProfileString();
          } else {
            this.createNotification("Error uploading profile picture", "Error");
          }
        })
        .catch(() => {
          this.createNotification("Error uploading profile picture", "Error");
        });
    },

    /**
     * CUSTOMER FEEDBACK
     */

    /**
     * Uploads a feedback and screenshots to the server
     * @param formData - The form data containing the feedback and the screenshots {feedback: {email: "", content: ""}, screenshots: []}
     * @returns {Promise<axios.AxiosResponse<any>>} - The response from the server
     */
    async uploadCustomerFeedback(formData) {
      try {
        let token = await getTokenRedirect(tokenRequest);
        const bearer = `Bearer ${token}`;
        const response = await axios
          .post(
            apiConfig.apiUrl + "/movement-assessment/api/feedback/submit",
            formData,
            {
              headers: {
                Authorization: bearer
              },
            }
          );
        return response;
      } catch (e) {
        throw new Error('Error sending feedback');
      }
    },

    /**
     * Fetches all customer feedback from the server
     * @param page - The page number
     * @param pageSize - The page size
     * @param sortOrder - The sort order
     * @returns {Promise<unknown>} - The response from the server
     */
    async fetchCustomerFeedback(
      page = 0,
      pageSize = 20,
      sortOrder = "desc"
    ) {

      const token = await getTokenRedirect(tokenRequest)
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .get(
            apiConfig.apiUrl +
            "/movement-assessment/api/feedback?sortOrder=" +
            sortOrder +
            "&page=" +
            page +
            "&pageSize=" +
            pageSize,
            {
              headers: {Authorization: bearer, 'Content-Type': 'application/json'},
            }
          )
          .then((response) =>
            resolve(this.feedbackArrayToFeedbackGlobal(response))
          )
          .catch((reason) => {
            console.log(reason);
            this.createNotification("Error fetching feedback", "Error");
            reject(reason);
          });
      });
    },

    /**
     * CUSTOMER DATA
     */

    async fetchCustomerTierMetadata() {
      await msalInstance.acquireTokenSilent(tokenRequest);
      const activeAccount = msalInstance.getActiveAccount();
      if (
        activeAccount === undefined ||
        null ||
        !activeAccount?.idTokenClaims
      ) {
        msalInstance.logoutRedirect().catch((e) => {
          console.log("Error logging out", e);
        });
        return null;
      }
      const token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      let organisation = activeAccount.idTokenClaims.extension_Team;
      let fullName =
        activeAccount.idTokenClaims.given_name +
        " " +
        msalInstance.getActiveAccount().idTokenClaims.family_name;
      let email = activeAccount.idTokenClaims.emails[0];
      let measurementSystem =
        activeAccount.idTokenClaims?.extension_MeasurementSystem;

      return axios
        .post(
          apiConfig.apiUrl + "/customer-tier/api/customer/me",
          {
            organisation: organisation,
            fullName: fullName,
            email: email,
            measurementSystem: measurementSystem,
          },
          {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.currentCustomer.setData(response.data);
          this.emit("customerData");
        })
        .catch((reason) => {
          console.log(reason);
          this.createNotification("Error fetching your data", "Error");
        });
    },

    async fetchAllCustomersMetadata(sortType = "expiration",
                                    page = 0,
                                    pageSize = 20,
                                    sortOrder = "desc",
                                    searchText = "",
                                    tier = -1) {
      const token = await getTokenRedirect(tokenRequest)
      const bearer = `Bearer ${token}`;


      return new Promise((resolve, reject) => {
        axios
          .get(
            apiConfig.apiUrl +
            "/customer-tier/api/customer/all?" +
            "pageSize=" + pageSize +
            "&page=" + page +
            "&sortType=" + sortType +
            "&sortOrder=" + sortOrder +
            "&searchText=" + searchText +
            "&tier=" + tier,
            {
              headers: {Authorization: bearer, 'Content-Type': 'application/json'},
            }
          )
          .then((response) => {
            if (page === 0) {
              this.allCustomers = [];
            }
            this.emit('responseLength', response.data.length)
            response.data.forEach((customerResponse) => {
              let customer = new CustomerMetadata()
              customer.setData(customerResponse);
              if (this.allCustomers.find(
                (cus) => cus.customerId === customer.customerId
              ) === undefined)
                this.allCustomers.push(customer);
              else {
                const customerIndex = this.allCustomers.findIndex(
                  (cus) => cus.customerId === customer.customerId
                );
                this.allCustomers[customerIndex] = customer;
              }
            })
            resolve(this.allCustomers);
          })
          .catch((reason) => {
            console.log(reason);
            this.createNotification("Error fetching customers data", "Error");
            reject(reason);
          });
      });
    },

    async updateCustomerMetadata(customerId, updatedData) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      return new Promise((resolve, reject) => {
        axios.put(
          apiConfig.apiUrl + "/customer-tier/api/customer/" + customerId,
          updatedData,
          {
            headers: {Authorization: bearer, 'Content-Type': 'application/json'},
          }
        ).then((response) => {
          let updatedCustomer = this.allCustomers.find((cus) => (cus.customerId === customerId))
          updatedCustomer.expirationDate = response.data.expirationDate;
          updatedCustomer.tier = response.data.tier;
          updatedCustomer.comments = response.data.comments;
          resolve(true)
        }).catch((reason) => {
          console.log(reason)
          reject(reason);
        });
      });
    },

    //Sorting algorithms----------------------------------------------------

    sortGroupsByName() {
      if (!this.groups) return;
      this.groups.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    },

    sortGroupsByMembers() {
      this.groups.sort((a, b) => {
        let g1 = this.athletes.filter((athlete) =>
          athlete.groups
            ? athlete.groups.map((group) => group.groupId).includes(a.groupId)
            : false
        );
        let g2 = this.athletes.filter((athlete) =>
          athlete.groups
            ? athlete.groups.map((group) => group.groupId).includes(b.groupId)
            : false
        );

        if (g1.length > g2.length) {
          return -1;
        }
        if (g1.length < g2.length) {
          return 1;
        }
        return 0;
      });
    },

    sortAthletesByAssessment() {
      // Collect the most recent assessment timestamp for each athlete
      const athleteAssessmentTimestamps = new Map();

      this.assessments.forEach(assessment => {
        const athleteId = assessment.athleteId;

        if (assessment.timestamp && (!athleteAssessmentTimestamps.has(athleteId) || assessment.timestamp > athleteAssessmentTimestamps.get(athleteId))) {
          athleteAssessmentTimestamps.set(athleteId, assessment.timestamp);
        }
      });


      // Sort athletes based on most recent assessment timestamps
      this.athletes.sort((a, b) => {
        const timestampA = athleteAssessmentTimestamps.get(a.athleteId);
        const timestampB = athleteAssessmentTimestamps.get(b.athleteId);

        if (timestampA && timestampB) {
          return timestampB.localeCompare(timestampA);
        } else if (timestampA) {
          return -1;
        } else if (timestampB) {
          return 1;
        }

        return 0;
      });
    },

    sortAthletesByFirstName() {
      if (!this.athletes) {
        return;
      }

      this.athletes.sort((a, b) => {
        if (a.firstName.localeCompare(b.firstName) == 0) {
          if ((!a.lastName || a.lastName == '') && (!b.lastName || b.lastName == '')) {
            // If both last names are null or empty they're equal
            return 0
          } else if (!a.lastName || a.lastName == '') {
            // If only a's last name is null or empty, it comes after b
            return 1;
          } else if (!b.lastName || b.lastName == '') {
            // If only b's last name is null or empty, it comes before a
            return -1;
          } else {
            // Sort by last name, then by first name
            const lastNameComparison = a.lastName.localeCompare(b.lastName);
            if (lastNameComparison === 0) {
              // If last names are the same, sort by first name
              return a.firstName.localeCompare(b.firstName);
            }
            return lastNameComparison;
          }
        }
        return a.firstName.localeCompare(b.firstName);
      });
    },

    sortAthletesByLastName() {
      if (!this.athletes) {
        return;
      }
      this.athletes.sort((a, b) => {
        // Handle null and empty last names
        if ((!a.lastName || a.lastName == '') && (!b.lastName || b.lastName == '')) {
          // If both last names are null or empty, sort by first name
          return a.firstName.localeCompare(b.firstName);
        } else if (!a.lastName || a.lastName == '') {
          // If only a's last name is null or empty, it comes after b
          return 1;
        } else if (!b.lastName || b.lastName == '') {
          // If only b's last name is null or empty, it comes before a
          return -1;
        } else {
          // Sort by last name, then by first name
          const lastNameComparison = a.lastName.localeCompare(b.lastName);
          if (lastNameComparison === 0) {
            // If last names are the same, sort by first name
            return a.firstName.localeCompare(b.firstName);
          }
          return lastNameComparison;
        }
      });
    },

    createNotification(message, type) {
      const notification = {message: message, type: type};
      this.notifications.push(notification);
      setTimeout(() => {
        this.notifications.splice(this.notifications.indexOf(notification), 1);
      }, 6000);
    },

    assessmentArrayToAssessmentGlobal(assessmentArray) {
      let tempAssessment = assessmentArray.data;
      tempAssessment.map((assessment) => {
        // Add exercise name to assessment
        const exercise = this.exercises.find((exercise) =>
          exercise.exerciseId === assessment.exerciseId);

        if (exercise != null) assessment.exerciseName = exercise.name;
        // find the index of the assessment in the global array
        const assessmentIndex = this.assessments.findIndex((assess) =>
          assess.assessmentId === assessment.assessmentId);

        // if the assessment is not in the global array, add it to the array
        if (assessmentIndex == null || assessmentIndex === -1) {
          this.assessments.push(assessment);
        } else { // if the assessment is in the global array, update it
          this.assessments[assessmentIndex] = assessment;
        }
      });

      return tempAssessment;
    },


    notesArrayToAssessmentGlobal(notesArray) {
      let tempNote = notesArray.data;
      tempNote.map((note) => {
        if (
          this.notes.find(
            (n) => n.noteId === note.noteId
          ) === undefined
        ) {
          this.notes.push(note);
        } else {
          const noteIndex = this.notes.findIndex(
            (n) => n.noteId === note.noteId
          );
          this.notes[noteIndex].noteId = note.noteId;
          this.notes[noteIndex].assessmentId = note.assessmentId;
          this.notes[noteIndex].editedOn = note.editedOn;
        }
      });
      return tempNote;
    },

    feedbackArrayToFeedbackGlobal(feedbackArray) {
      let tempFeedback = feedbackArray.data;
      tempFeedback.map((feedback) => {
        if (
          this.customerFeedback.find(
            (feed) => feed.feedbackId === feedback.feedbackId
          ) === undefined
        ) {
          this.customerFeedback.push(feedback);
        } else {
          const feedbackIndex = this.customerFeedback.findIndex(
            (feed) => feed.feedbackId === feedback.feedbackId
          );
          this.customerFeedback[feedbackIndex].feedbackId =
            feedback.feedbackId;
          this.customerFeedback[feedbackIndex].email = feedback.email;
          this.customerFeedback[feedbackIndex].content = feedback.content;
          this.customerFeedback[feedbackIndex].screenshots = feedback.screenshots;
          this.customerFeedback[feedbackIndex].createdAt = feedback.createdAt;
        }
      });
      return tempFeedback;
    },
  },
  getters: {
    getAssessmentsByAthlete: (state) => {
      return (Id) =>
        state.assessments.filter(
          (assessment) => assessment.athleteId === parseInt(Id) && !assessment.isHidden
        ) || [];
    },
    getAssessment: (state) => {
      return (Id) =>
        state.assessments.find(
          (assessment) => assessment.assessmentId === parseInt(Id)
        ) || {};
    },
    getAthlete: (state) => {
      return (Id) =>
        state.athletes.find(
          (athlete) => athlete.athleteId === parseInt(Id)
        ) || {
          name: "",
          dob: "",
          bodyweight: "",
        };
    },
    getTimeOfLastAthleteUpdate: (state) => {
      return state.timeOfLastAthleteUpdated;
    },
    getTimeOfLastAthleteCreate: (state) => {
      return state.timeOfLastAthleteCreated;
    },
  },
});
